<template>
  <li
    v-if="canViewMenuItemBasedOnRole(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'"/>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import {useUtils as useAclUtils} from '@core/libs/acl';
import {BLink, BBadge} from 'bootstrap-vue';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import {useLocalisation} from "@/shared/composables/use-localisation";

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {isActive, linkProps, updateIsActive} = useVerticalNavMenuLink(props.item);
    const {t} = useLocalisation();
    let storedUser = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
    let CURRENT_ROLE = {
      displayableRole: "Invité",
      role: "guest"
    }
    
    if (storedUser != null) {
      CURRENT_ROLE = storedUser.role;
    } 

    const canViewMenuItemBasedOnRole = function (menuItem) {
      return Roles.roleIsAllowed(CURRENT_ROLE.role, menuItem.roles);
    };



    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewMenuItemBasedOnRole,
      // i18n
      t,
    };
  },

};
</script>
