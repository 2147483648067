import Roles from "@/modules/authnz/models/role";

export default [
  {
    header: 'menu_group.admins',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  {
    title: 'menu.admins',
    route: 'administrators',
    icon: 'UsersIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role]
  },
  /*
  {
    title: 'menu.report',
    icon: 'FileTextIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  */
];
