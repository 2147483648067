import {useUtils as useAclUtils} from '@core/libs/acl';

const {t} = useLocalisation();
const {canViewVerticalNavMenuHeader} = useAclUtils();
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import {useLocalisation} from "@/shared/composables/use-localisation";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.role;

    const span = h('span', {}, t(this.item.header));
    const icon = h('feather-icon', {props: {icon: 'MoreHorizontalIcon', size: '18'}});
    if (Roles.roleIsAllowed(CURRENT_ROLE?.role, this.item.roles)) {
      return h('li', {class: 'navigation-header text-truncate'}, [span, icon]);
    }
    return h();
  },
};
