<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" >
      <dark-Toggler class="d-none d-lg-block"/>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-3 ml-2" size="sm" variant="outline-primary" @click="showRequestModal" v-if="true" > 
        <feather-icon class="mr-50" icon="HelpCircleIcon" size="16"/>
        Besoin d'aide?
      </b-button>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      
 
      
      <b-nav-item-dropdown class="dropdown-user" right toggle-class="d-flex align-items-center dropdown-user-link">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ currentUserFullName }}</p>
            <span class="user-status">{{ currentUserRole }}</span>
          </div>
          <b-avatar
            badge
            badge-variant="success"
            class="badge-minimal"
            size="40"
            :variant="avatarColors(flattenUUIDtoBase(currentUserId))"

          />

        </template #button-content>
        <b-dropdown-item :to="{ name: 'user-profile', params: { id: 'me' }}" link-class="d-flex align-items-center">
          <feather-icon class="mr-50" icon="UserIcon" size="16"/>
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-divider/>

        <b-dropdown-item link-class="d-flex align-items-center">
          <logout-button/>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <RequestModal 
      ref="request-modal-ref"
    />
  </div>
</template>

<script>
import {BAvatar,BButton, BDropdownDivider, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import LogoutButton from "@/modules/authnz/components/LogoutButton";
import RequestModal from "@/modules/requests/components/requestModal";
import {useApplicationContext} from "@/shared/composables/use-application-context";

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    LogoutButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    RequestModal,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {refOf} = useApplicationContext();

    const showRequestModal = () => {
      refOf('request-modal-ref').$children[0].show();
    };

    return {
      showRequestModal
    }
  },
  computed: {
    currentUserFullName() {
      return this.$store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.fullName;
    },
    currentUserRole() {
      return this.$store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.role?.displayableRole;
    },
    currentUserId() {
      return this.$store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.id;
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;

      return character => character ? Math.round(parseInt(character.slice(-1),UUID_BASE)/BASE) : null;
    }
    ,
    avatarColors() {

      const avatarColors = {
        "1":"primary",
        "2":"dark",
        "3":"info",
        "4":"success",
        "5":"danger",
        "6":"warning",
        "7":"info",
        "8":"secondary",
      }
      return character => avatarColors[character]
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
};
</script>
