import Roles from "@/modules/authnz/models/role";

export default [
  {
    header: 'menu_group.org',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  /*
  {
    title: 'menu.organisation.dashboard',
    route: 'organisation-overview',
    icon: 'CompassIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  */
  {
    title: 'menu.organisation.groups',
    route: 'organisation-groups',
    icon: 'UsersIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  {
    title: 'menu.agencies',
    route: 'agencies',
    icon: 'HomeIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  {
    title: 'menu.organisation.bookings',
    route: 'organisation-reservations',
    icon: 'FileIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
  {
    title: 'menu.organisation.reporting',
    route: 'organisation-reporting',
    icon: 'BarChartIcon',
    roles: [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]
  },
];
