import {ref, nextTick} from '@vue/composition-api';

export function useValidatorsWithAutoFormDataCleanUp(formData, formResetTriggerFunction = () => true) {
  const clearFormDataFn = () => Object.keys(formData).forEach((key) => formData[key] = '');
  return useValidators(clearFormDataFn, clearFormDataFn, formResetTriggerFunction);
}

export function useValidators(resetFormData = () => {}, clearFormData = () => {}, formResetTriggerFunction = () => true) {
  const refFormObserver = ref(null);

  const resetObserver = () => {
    refFormObserver.value.reset();
  };

  const getValidationState = ({dirty, validated, required: fieldRequired, changed, valid = null}) => {
    const result = dirty || validated ? valid : null;
    return !fieldRequired && !changed ? null : result;
  };

  const triggerBasedResetForm = () => {
    if (formResetTriggerFunction()) {
      resetForm();
    }
  };

  const resetForm = () => {
    resetFormData();
    if (refFormObserver.value != null) {
      nextTick(() => {
        resetObserver();
      });
    }
  };

  const clearForm = () => {
    clearFormData();
    nextTick(() => {
      resetObserver();
    });
  };

  return {
    refFormObserver,
    resetObserver,
    getValidationState,
    triggerBasedResetForm,
    resetForm,
    clearForm,
  };
}
