import {useApplicationContext} from "@/shared/composables/use-application-context";

export const useRequestTemplater = (email) => {
  const DEFAULT_EMAIL = "groupes.etcie@transat.com";
  const {logger} = useApplicationContext();

  const REQUEST_EMAIL_TO = email || DEFAULT_EMAIL;

  const REQUEST_TYPES = {
    PLATFORM_QUESTION :"platform_question",
    GENERIC_GROUP_QUESTION :"generic_group_question",
    SPECIAL_REQUEST_GROUP:"special_request_group",
    CHANGE_RESERVATION:"change_reservation",
    CANCEL_RESERVATION:"cancel_reservation"
  }

  const generateEmailLinkByType = (type, data) => {
    logger.info(`Generate ${type} template`);
    try {

  
    if (type == REQUEST_TYPES.GENERIC_GROUP_QUESTION) {
      return generateGenericGroupQuestionLink(data);
    } else if (type == REQUEST_TYPES.PLATFORM_QUESTION) {
      return generatePlatformQuestionLink(data);
    } else if (type == REQUEST_TYPES.SPECIAL_REQUEST_GROUP) {
      return generateSpecialRequestLink(data);
    } else if (type == REQUEST_TYPES.CHANGE_RESERVATION) {
      return generateChangeReservationLink(data);
    } else if (type == REQUEST_TYPES.CANCEL_RESERVATION) {
      return generateCancelReservationLink(data);
    }

  } catch (error) {
    console.error(error)
    return ""
  }
  
};

  const generateGenericGroupQuestionLink = (data) => {
    let to = REQUEST_EMAIL_TO;

    let subject = `Question générique pour le group: ${data.group.name}`
    let body = `
    Agent: ${data.authUser.fullName}
    %0A%0A
    Nom du group: ${data.group.name} (www.monicagroups.com/groups/${data.group.id})
    %0A%0A
    Question: ${data.description}
    `
    let cc = ``;
    let href = `mailto:${to}?subject=${subject}&body=${body}`
    return href;
  }

  const generatePlatformQuestionLink = (data) => {
    let to = REQUEST_EMAIL_TO;

    let subject = `Question à propos de la plateforme Group X`
    let body = `
    Agent: ${data.authUser.fullName}
    %0A%0A
    Question: ${data.description}
    `
    let cc = ``;
    let href = `mailto:${to}?subject=${subject}&body=${body}`
    return href;
  }

  const generateSpecialRequestLink = (data) => {
    let to = REQUEST_EMAIL_TO;

    let subject = `Requête spécial`
    let body = `
    Agent: ${data.authUser.fullName}
    %0A%0A
    Nom du group: ${data.group.name} (www.monicagroups.com/groups/${data.group.id})
    %0A%0A
    Question: ${data.description}
    `
    let cc = ``;
    let href = `mailto:${to}?subject=${subject}&body=${body}`
    return href;
  }

  const generateChangeReservationLink = (data) => {
    let to = REQUEST_EMAIL_TO;

    let subject = `Question à propos de la plateforme Group X`
    let body = `
    Agent: ${data.authUser.fullName}
    %0A%0A
    Numéro de reservation: ${data.reservation.reservation_number}
    %0A%0A
    Nom du group: ${data.reservation.group_name} (www.monicagroups.com/groups/${data.reservation.group_id})
    %0A%0A
    Question: ${data.description}
    `

    let cc = ``;
    let href = `mailto:${to}?subject=${subject}&body=${body}`
    return href;
  }

  const generateCancelReservationLink = (data) => {
    let to = REQUEST_EMAIL_TO;

    let subject = `Question à propos de la plateforme Group X`
    let body = `
    Agent: ${data.authUser.fullName}
    %0A%0A
    Numéro de reservation: ${data.reservation.reservation_number}
    %0A%0A
    Nom du group: ${data.reservation.group_name} (www.monicagroups.com/groups/${data.reservation.group_id})
    %0A%0A
    Question: ${data.description}
    `
    let cc = ``;
    let href = `mailto:${to}?subject=${subject}&body=${body}`
    return href;
  }

  return {
    generateEmailLinkByType
  }

};
