<template>
  <b-modal
    id="group-advanced-information-modal"
    :title="t('group.request.navbar_title')"
    :ok-title="t('group.request.send')"
    :cancel-title="t('button.cancel')"
    cancel-variant="outline-secondary"
    size="lg"
    @ok="submitFormData"
    @cancel="resetForm"
    @show="fillDataIfProvided"
    no-close-on-backdrop
  >
    <b-form>
      <b-row>

        <b-col md="6">
          <b-form-group
            :label="t('group.request.request_type_label')"
            label-for="request-type"
          >

            <v-select
              id="request-type"
              ref="request-type"
              v-model="formData.type"
              :options="request_types_options"
              label="text"
              :reduce="text => text.value"
            />

          </b-form-group>

        </b-col>
      </b-row>
      <b-row v-if="formData.type == REQUEST_TYPES.GENERIC_GROUP_QUESTION || formData.type == REQUEST_TYPES.SPECIAL_REQUEST_GROUP">
        <b-col md="6" >
          <b-form-group
            label="Quel est le groupe?"
            label-for="request-type"
          >

            <v-select
              id="request-type"
              ref="request-type"
              v-model="formData.group"
              :options="groups"
              label="name"
            />

          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="t('group.request.question_label')" label-for="description">
            <b-form-textarea
              rows="4"
              size="sm"
              :placeholder="t('group.request.navbar_title')"
              v-model="formData.description"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormTags,
  BFormTextarea,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";
import {reactive, ref, watch} from "@vue/composition-api/dist/vue-composition-api";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useRequestTemplater} from "@/modules/requests/composables/use-request-templater";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";

export default {
  name: 'group-advanced-information-modal',
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormTags,
    BFormFile,
    BImg
  },
  props: {
    reservationNumber: {
      type: String,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, {emit}) {
    const {updateGroupAdvancedInformation, uploadImage} = useGroupApi();
    const {forceUpdate, store} = useApplicationContext();
    const {t} = useLocalisation();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {getAllAvailableGroups} = useGroupApi();

    let emailForRequest = null;
    try{
      if (store.state[AUTH_STORE_NAMESPACE].orgSettings?.request_email) {
        emailForRequest = store.state[AUTH_STORE_NAMESPACE].orgSettings.request_email
      }
    } catch (error) {
      console.error(error)
    }

    const {generateEmailLinkByType} = useRequestTemplater(emailForRequest);

    const REQUEST_TYPES = {
      PLATFORM_QUESTION :"platform_question",
      GENERIC_GROUP_QUESTION :"generic_group_question",
      SPECIAL_REQUEST_GROUP:"special_request_group"
    }
    const currentAuthUser = ref({});

    currentAuthUser.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];


    let formData = reactive({
      link: '',
      type: '',
      authUser: currentAuthUser
    });


    const groups = ref([]);

    const fetchGroups = async () => {
      try {
        groups.value = await getAllAvailableGroups();
      } catch {
        displayErrorMessage('Une erreur est survenue en essayant de chercher les groupes disponibles');
      }
    };

    watch(formData, async () => {
      if (formData.type == REQUEST_TYPES.GENERIC_GROUP_QUESTION || formData.type == REQUEST_TYPES.SPECIAL_REQUEST_GROUP) {
        fetchGroups();
      }
    })

    const fillDataIfProvided = () => {
      if (props.action === 'update') {
        Object.assign(formData, props.information);
        forceUpdate();
      }
    };

    const submitFormData = async () => {
      try {
        switch (formData.type){
          case REQUEST_TYPES.GENERIC_GROUP_QUESTION:
            window.location.href = generateEmailLinkByType(REQUEST_TYPES.GENERIC_GROUP_QUESTION, formData)
            break;
          case REQUEST_TYPES.PLATFORM_QUESTION:
            window.location.href = generateEmailLinkByType(REQUEST_TYPES.PLATFORM_QUESTION, formData)
            break;
          case REQUEST_TYPES.SPECIAL_REQUEST_GROUP:
            window.location.href = generateEmailLinkByType(REQUEST_TYPES.SPECIAL_REQUEST_GROUP, formData)
            break;
        }

        displaySuccessMessage('Votre email sera généré dans votre gestionnaire de courriel par défault');
        resetForm();
      } catch (e){
        console.error(e)
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }
    };

    const request_types_options = [
      {text: "Support/Commentaire pour la plateforme", value: REQUEST_TYPES.PLATFORM_QUESTION},
      {text: "Question générique sur un groupe", value: REQUEST_TYPES.GENERIC_GROUP_QUESTION},
      {text: "Demande spéciale pour un groupe", value: REQUEST_TYPES.SPECIAL_REQUEST_GROUP}
    ];


    const {resetForm} = useValidatorsWithAutoFormDataCleanUp(formData);

    return {
      formData,
      t,
      resetForm,
      submitFormData,
      fillDataIfProvided,
      request_types_options,
      REQUEST_TYPES,
      groups
    };
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
