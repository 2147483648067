export const useApiRequestMappers = () => {
  const keysToSnakeCase = (object) => {
    if (isObject(object)) {
      const convertedObject = {};

      Object.keys(object)
        .forEach((key) => {
          convertedObject[camelToSnakeCase(key)] = keysToSnakeCase(object[key]);
        });

      return convertedObject;
    } else if (isArray(object)) {
      return object.map((arrayEntry) => {
        return keysToSnakeCase(arrayEntry);
      });
    }

    return object;
  };

  const keysToCamel = (object) => {
    if (isObject(object)) {
      const n = {};

      Object.keys(object)
        .forEach((k) => {
          n[toCamel(k)] = keysToCamel(object[k]);
        });

      return n;
    } else if (isArray(object)) {
      return object.map((i) => {
        return keysToCamel(i);
      });
    }

    return object;
  };

  return {
    keysToSnakeCase,
    keysToCamel,
  };
};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const camelToSnakeCase = (s) => {
  return s.replace(/([A-Z])/g, (uppercaseChar) => {
    return `_${uppercaseChar.toLowerCase()}`;
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};
