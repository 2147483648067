import Roles from "@/modules/authnz/models/role";
import { AGENCY_ROUTE_NAMES } from "@/router/agency";
import {GENERIC_ROUTES_NAMES} from "@/router/app";

export default [
  {
    header: "menu_group.agency",
    roles: [Roles.AGENCY_ADMIN.role, Roles.AGENCY_AGENT.role],
  },
  {
    title: "menu.agency.dashboard",
    route: GENERIC_ROUTES_NAMES.HOME,
    icon: "CompassIcon",
    roles: [Roles.AGENCY_ADMIN.role, Roles.AGENCY_AGENT.role],
  },
  {
    title: "menu.profile",
    route: AGENCY_ROUTE_NAMES.AGENCY_PROFILE,
    icon: "HomeIcon",
    roles: [Roles.AGENCY_ADMIN.role],
  },
  {
    title: "menu.agency.groups",
    route: "agency-groups",
    icon: "UsersIcon",
    roles: [Roles.AGENCY_ADMIN.role, Roles.AGENCY_AGENT.role],
  },
  {
    title: "menu.agency.bookings",
    route: "agency-reservations",
    icon: "FileIcon",
    roles: [Roles.AGENCY_ADMIN.role, Roles.AGENCY_AGENT.role],
  },
  {
    title: "menu.agency.reporting",
    route: "agency-reporting",
    icon: "BarChartIcon",
    roles: [Roles.AGENCY_ADMIN.role],
  },
];
