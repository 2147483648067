import ToastificationContent from "@/shared/components/ToastificationContent";
import {getCurrentInstance} from "@/shared/composables/vue-component-instance";

export const useToastNotification = () => {
  const toast = getCurrentInstance().proxy.$toast;

  const displaySuccessMessage = (title, message) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title ? title : 'Operation effectuée avec succès',
        text: message ? message : null,
        icon: 'EditIcon',
        variant: 'success',
      },
    });
  };

  const displayErrorMessage = (title, errorMessage) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title ? title : 'Échec de l\'opération',
        text: errorMessage ? errorMessage : null,
        icon: 'EditIcon',
        variant: 'danger',
      },
    });
  };

  const displayWarningMessage = (title, errorMessage) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title ? title : 'Votre attention est requise',
        text: errorMessage ? errorMessage : null,
        icon: 'EditIcon',
        variant: 'warning',
      },
    });
  };
  const displayInfoMessage = (title, errorMessage) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title ? title : 'Votre attention est requise',
        text: errorMessage ? errorMessage : null,
        icon: 'EditIcon',
        variant: 'info',
      },
    });
  };


  return {displaySuccessMessage, displayErrorMessage, displayWarningMessage,displayInfoMessage};
};
